import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useCallback, useMemo, useState } from 'react';

import type { ModalProps } from '../../types';
import { classes } from './Provider.st.css';

type ModalComponent<T = unknown> = FC<PropsWithChildren<ModalProps & T>>;

type ModalPropsKeys = keyof ModalProps;

interface ModalContextType {
  showModal<T>(
    modal: ModalComponent<T>,
    additionalProps: Omit<T, ModalPropsKeys>,
  ): void;
  hideModal(): void;
}

export const ModalsContext = createContext<ModalContextType | null>(null);

export const ModalsContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [Modal, setModal] = useState<ModalComponent | null>(null);
  const [props, setProps] = useState<Record<string, unknown> | null>(null);

  const onModalClose = useCallback(() => {
    setIsOpen(false);
    setProps(null);
    setModal(null);
  }, []);

  const value = useMemo(
    () => ({
      showModal: <T,>(
        modal: ModalComponent<T>,
        additionalProps: Omit<T, ModalPropsKeys>,
      ) => {
        setIsOpen(true);
        setProps(additionalProps);
        setModal(modal as ModalComponent);
      },
      hideModal: onModalClose,
    }),
    [onModalClose],
  );

  return (
    <ModalsContext.Provider value={value}>
      <>
        {children}
        {Modal && (
          <div className={classes.modalWrapper}>
            <Modal {...props} isOpen={isOpen} onClose={onModalClose} />
          </div>
        )}
      </>
    </ModalsContext.Provider>
  );
};
